import React from 'react';
import Settings from '../Components/Settings';
import { Helmet } from "react-helmet";

export default function document() {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Prodocs | Settings</title>
                <link rel="canonical" href="/prodocs-logo.png" />
            </Helmet>
            <Settings />
        </div>
    )
}